import React from "react"
import { graphql } from "gatsby"
import * as styles from "../../styles/page/second.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

// markup
const IndexPage = ({data}) => {
  const markdownRemark = data.markdownRemark
  const { frontmatter } = markdownRemark
  const { title, description, slug } = frontmatter

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        path={slug}
      />

      <BreadCrumb
        parentLink="/about/"
        parentTitle="グリーンイノベーション基金事業概要等"
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={`${styles.pageTitleHeading} mb-6`}>{title}</h1>
      </div>

      <main className={styles.pageContents}>
        <h2 className={styles.pageContentsHeading2}>関連制度</h2>
        <p className="pb-3 leading-7">2050年カーボンニュートラルを実現するための税制・金融・規制改革等の関連施策、他のプロジェクトの公募についてご紹介します。</p>
        <ol className={`${styles.numberList} pb-2`}>
          <li><a className={`${styles.linkText} ${styles.pdf}`} href="https://www.meti.go.jp/main/yosan/yosan_fy2021/pdf/zeisei.pdf#page=4" target="_blank" rel="noreferrer noopener">カーボンニュートラルに向けた投資促進税制の創設</a></li>
          <li><a className={`${styles.linkText} ${styles.pdf}`} href="https://www.meti.go.jp/main/yosan/yosan_fy2021/pdf/zeisei.pdf#page=13" target="_blank" rel="noreferrer noopener">研究開発税制</a></li>
          <li><a className={`${styles.linkText} ${styles.pdf}`} href="https://www.meti.go.jp/main/yosan/yosan_fy2021/pr/ip/sansei_06.pdf" target="_blank" rel="noreferrer noopener">カーボンニュートラル実現に向けたトランジション推進のための利子補給事業</a></li>
          <li><a className={`${styles.linkText} ${styles.pdf}`} href="https://www.meti.go.jp/main/yosan/yosan_fy2020/hosei/pdf/hosei3_yosan_pr.pdf#page=12" target="_blank" rel="noreferrer noopener">先端低炭素設備導入促進補償制度推進事業</a></li>
          <li><a className={`${styles.linkText} ${styles.pdf}`} href="https://www.meti.go.jp/main/yosan/yosan_fy2021/pr/en/sangi_taka_29.pdf" target="_blank" rel="noreferrer noopener">クライメート・イノベーション・ファイナンス推進事業</a></li>
          <li><a className={`${styles.linkText} ${styles.pdf}`} href="https://www.meti.go.jp/shingikai/energy_environment/kankyo_innovation_finance/pdf/005_05_00.pdf#page=16" target="_blank" rel="noreferrer noopener">ゼロエミ・チャレンジ企業と投資家等との対話の場</a></li>
          <li><a className={`${styles.linkText} ${styles.outbound}`} href="https://www.meti.go.jp/policy/energy_environment/global_warming/zero-emission_challenge/index_zeroemi.html" target="_blank" rel="noreferrer noopener">（参考）「ゼロエミ・チャレンジ」について</a></li>
          <li><a className={`${styles.linkText} ${styles.outbound}`} href="https://www.nedo.go.jp/search/index.php?type=koubo" target="_blank" rel="noreferrer noopener">他のプロジェクトの公募情報</a></li>
        </ol>
      </main>

    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`